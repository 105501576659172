/***
 TaskQuark CCS Constants
*/
/*
 END OF TaskQuark CCS Constants
***/
/*
 TaskQuark Style Sheet 
*/
.taskquark-blue {
  color: #1d44ff;
}

.bg-taskquark-blue {
  background-color: #1d44ff;
}

.TQ-black {
  color: white !important;
  background-color: black !important;
}

.TQ-blue {
  color: white !important;
  background-color: blue !important;
}

.TQ-brown {
  color: white !important;
  background-color: brown !important;
}

.TQ-green {
  color: white !important;
  background-color: green !important;
}

.TQ-orange {
  color: black !important;
  background-color: orange !important;
}

.TQ-purple {
  color: white !important;
  background-color: purple !important;
}

.TQ-red {
  color: white !important;
  background-color: red !important;
}

.TQ-yellow {
  color: black !important;
  background-color: yellow !important;
}

.TQ-white {
  color: black !important;
  background-color: white !important;
}

.TQ-text-break {
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
}

.TQ-loading-icon {
  margin-top: 12px;
  margin-left: 10px;
  width: 20px;
  background-color: transparent;
}

.TQ-help-icon {
  font-size: 18px;
  vertical-align: top;
  color: blue;
}

.TQ-filter-area {
  float: right;
  margin-top: 6px;
  margin-right: 6px;
}

.TQ-header-filtered {
  background-color: #FFD580;
}

.TQ-filter-button {
  padding: 5px;
  border: 0px !important;
  outline: none !important;
  vertical-align: baseline;
  color: #1d44ff;
  background: none;
  background-color: transparent;
}

.TQ-clear-button {
  border: 0;
  margin-left: 4px;
  vertical-align: text-top;
  color: white;
  background-color: red;
}

.TQ-add-button {
  position: fixed;
  top: 27.5px;
  left: 50%;
  width: 50px;
  height: 50px;
  z-index: 100;
  text-align: center;
  border-radius: 50px;
  box-shadow: 2px 2px 4px #AAAAAA;
}
@media (max-width: 768px) {
  .TQ-add-button {
    top: calc(100% - 85px);
    left: calc(100% - 70px);
  }
}

.TQ-scroll-top-button {
  position: fixed;
  top: 27.5px;
  left: calc(50% - 65px);
  width: 50px;
  height: 50px;
  z-index: 100;
  text-align: center;
  border-radius: 50px;
  box-shadow: 2px 2px 4px #AAAAAA;
}
@media (max-width: 768px) {
  .TQ-scroll-top-button {
    top: calc(100% - 85px);
    left: calc(100% - 135px);
  }
}

.TQ-action-button {
  border: 0;
  vertical-align: text-top;
  color: black;
  background-color: orange;
  font-weight: normal;
}

.TQ-badge {
  color: white;
  background-color: #1d44ff;
  font-weight: 600 !important;
}

.TQ-checkbox {
  border: 0;
  background-color: white;
  font-weight: bold;
}

.TQ-checkbox-label {
  margin-left: 5px;
  margin-right: 15px;
  margin-bottom: 0px;
  font-weight: bold;
}

.TQ-datepicker {
  color: #1d44ff;
  padding: 0px;
}

.TQ-datepicker-input {
  padding: 0px;
  width: 100px;
  color: black;
  text-align: center;
  background-color: #e9e9ed;
}

.TQ-input-text {
  border: 0;
  background-color: white;
  font-weight: bold;
}

.TQ-table {
  table-layout: fixed;
  width: 100%;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: lightgray;
  border-collapse: collapse;
}

.TQ-table-header {
  background-color: lightyellow !important;
}

.tableQuarter {
  width: 25%;
  float: left;
}

.tableEqual {
  float: left;
}

.tableStacked {
  float: none;
  width: 100%;
}

.TQ-col-1 {
  width: 8%;
}

.TQ-col-2 {
  width: 16%;
}

.TQ-col-3 {
  width: 24%;
}

.TQ-col-4 {
  width: 32%;
}

.TQ-col-5 {
  width: 40%;
}

.TQ-col-6 {
  width: 48%;
}

.TQ-col-7 {
  width: 56%;
}

.TQ-col-8 {
  width: 64%;
}

.TQ-col-9 {
  width: 72%;
}

.TQ-col-10 {
  width: 80%;
}

.TQ-col-11 {
  width: 88%;
}

.TQ-col-12 {
  width: 96%;
}

.TQ-td {
  display: block;
  padding: 4px;
}

.TQ-cursor {
  cursor: pointer;
}

.TQ-selector {
  background-color: #e9e9ed;
}

.TQ-time {
  vertical-align: text-top;
  text-align: right;
}

.TQ-time-current {
  font-weight: bold;
  color: red;
}

.TQ-time-past {
  font-weight: normal;
  color: black;
}

.TQ-color-button {
  margin-left: 0px;
  vertical-align: text-top;
}

.task-planning {
  color: grey;
}

.task-todo {
  color: black;
}

.task-doing {
  color: blue;
}

.task-waiting {
  color: purple;
}

.task-stopped {
  color: red;
}

.task-done {
  color: black;
  text-decoration: line-through;
}

.task-canceled {
  color: red;
  text-decoration: line-through;
}

/***
  Responsive styling
*/
.only-desktop {
  visibility: hidden;
}
@media (min-width: 1280px) {
  .only-desktop {
    visibility: visible;
  }
}

.only-mobile {
  visibility: hidden;
}
@media (max-width: 768px) {
  .only-mobile {
    visibility: visible;
  }
}

/***
 Angular CDK
***/
:host ::ng-deep .cdk-drag-preview {
  background-color: #c3e6cb;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

:host ::ng-deep .cdk-drag-placeholder {
  opacity: 0.5;
  visibility: hidden;
}

:host ::ng-deep .cdk-drop-list-dragging {
  background-color: #d4f7dc;
}

:host ::ng-deep .cdk-drag:active {
  background-color: #c3e6cb;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/***
 Angular Material restyling
***/
:host ::ng-deep .mat-mdc-button-base {
  font-size: 10px !important;
  width: 42px !important;
  height: 42px !important;
}

:host ::ng-deep .mat-mdc-icon-button {
  vertical-align: middle;
  margin: 0px !important;
  padding: 0px !important;
}
:host ::ng-deep .mat-mdc-icon-button svg {
  vertical-align: middle !important;
}

/***
  Global styling
*/
html {
  height: 100%;
  max-height: 100%;
}

body {
  height: 100%;
  max-height: 100%;
  margin: 0;
  overflow-y: hidden;
}

a {
  outline: none;
}

select {
  background-color: #e9e9ed;
  outline: none !important;
  box-shadow: none !important;
  text-align: left;
}

.row:before, .row:after {
  display: flex !important;
}