/***
 TaskQuark CCS Constants
*/
/*
 END OF TaskQuark CCS Constants
***/
/*
 SAM styling
*/
.samPage {
  width: 100%;
  padding: 0;
  /*
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  */
  flex-basis: auto;
  -webkit-box-orient: vertical;
}

.samOutlet {
  width: 100%;
  height: calc(100vh - 55px - 20px);
  overflow-y: scroll;
  padding-bottom: 20px;
}

.samPane {
  width: 100%;
  padding-bottom: 20px;
}

.samPaneHeader {
  overflow: auto;
  margin-bottom: 2px;
}

.samPaneTitle {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

.samPaneSubtitle {
  float: left;
  margin-top: 13px;
  margin-left: 10px;
  margin-bottom: 1px;
  padding-left: 5px;
  padding-right: 5px;
}

.samPaneFooter {
  height: 60px;
}

.samSidebar {
  background: #1d44ff;
}

.samMandatory::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 0.6; /* Firefox */
}

.samMandatory::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 0.6; /* Firefox */
}

.samMandatory::-ms-input-placeholder .samMandatory {
  /* Microsoft Edge */
  color: red;
  opacity: 0.6;
}

.samFooter {
  height: 20px;
}